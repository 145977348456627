import React from 'react'
import { Link } from 'gatsby'

const LearningPathTiles = ({ learningPaths, gridTilesContainerClass='grid-tiles-container' }) => {
  return (
    <div className={gridTilesContainerClass}>
      {learningPaths.map(({ frontmatter }, idx) => {
        const key = `${frontmatter.path.substring(1)}-${idx + 1}`
        return (
          <Link to={`${frontmatter.path}`} key={key}>
            <div className={`grid-tile-item`}>
              <div className="tile-item--title">
                {frontmatter.title}
              </div>
              <hr />
              <div className='tile-item-description'>
                <ul>
                  {frontmatter.description.map((descriptionBullet, idx) => {
                    return (<li key={idx}>{descriptionBullet}</li>)
                  })}
                </ul>
              </div>
              <hr />
              <div className="tile-item--tags">
                {frontmatter.tags.map((value, idx) => (
                  <div className="tags--tag" key={`${value}-${idx}`}>
                    {value}
                  </div>
                ))}
              </div>
            </div>

          </Link>
        )
      })}
    </div>
  )
}

export default LearningPathTiles

