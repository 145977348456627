import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import LearningPathTiles from './components/LearningPathTiles'

const Learn = ({ data, location }) => {
  const params = new URLSearchParams(location.search)
  var tag = params.get('tag') || ''
  var learningPaths = []

  tag === '' 
    ? learningPaths = data.learningPaths.nodes
    : learningPaths = data.learningPaths.nodes.filter(
      learn => learn.frontmatter.tags.includes(tag)
    )

  return (
    <div className="page--learn wrapper">
      <Helmet title={`Learning Paths | Couchbase Developer Portal`} />
      <div className="flex flex-row">
        <div className="w-full">
          <h1><span>Learning Paths </span><aside className="inline-block font-normal">on Couchbase</aside></h1>
          <p>Curated learning paths for deeper dives into specific areas of Couchbase technologies.</p>
          <LearningPathTiles learningPaths={learningPaths} />
        </div>
      </div>
    </div>
  )
}

export default Learn

export const query = graphql`
  query learningPathsQuery {
    learningPaths: allMarkdownRemark(filter: { frontmatter: { content_type: { eq: "learn" } } }) {
      nodes {
        frontmatter {
          title
          path
          content_type
          tags
          technology
          related_paths
          description
          length
        }
        id
      }
    }
  }
`
